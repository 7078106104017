/*
 * @Author: caizhihao
 * @Date: 2023-08-07 17:40:31
 * @LastEditors: caizhihao 177745994@qq.com
 * @LastEditTime: 2023-08-09 10:37:26
 * @FilePath: \PriorityPay-web\src\main.js
 * @Description:
 *
 */
import { createApp } from 'vue'
import App from './App.vue'
import './assets/css/reset.css'
import i18n from './lang/i18n'
import router from './router'

const app = createApp(App)
app.config.globalProperties.$t = i18n.global.t

app.use(i18n)
app.use(router)

app.mount('#app')
