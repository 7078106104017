/*
 * @Author: caizhihao
 * @Date: 2023-08-07 17:40:32
 * @LastEditors: 张霞 1738830106@qq.com
 * @LastEditTime: 2024-08-29 15:56:47
 * @FilePath: /ChileCashy-web/src/lang/i18n.js
 * @Description:
 *
 */
import { createI18n } from 'vue-i18n'
import EN from '../lang/en-US'
import ID from '../lang/en-ID'
import ES from '../lang/en-ES'
const messages = {
	ID: { ...ID },
	EN: { ...EN },
	ES: { ...ES },

}

const langType = sessionStorage.getItem('lang') || 'EN'
const i18n = createI18n({
	locale: langType,
	messages,
})
export default i18n
