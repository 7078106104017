/*
 * @Author: caizhihao
 * @Date: 2023-08-07 17:40:32
 * @LastEditors: 张霞 1738830106@qq.com
 * @LastEditTime: 2024-08-29 15:25:07
 * @FilePath: /PriorityPay/src/lang/en-ID/index.js
 * @Description:
 *
 */
export default {
	priority: {
		home: 'Beranda',
		product: 'Produk',
		about: 'Tentang Kami',
		language: 'Bahasa',
		reliablePartner: 'Rekan anda yang handal',
		homeText1: 'Satu solusi untuk kebutuhan transaksi keuangan personal dan bisnis anda',
		details: 'Detil',
		personal: 'Perorangan',
		business: 'Bisnis',
		homeText2: 'Bergabunglah bersama kami! ',
		introduction: 'Introduksi',
		contact: 'Kontak Kami',
		address: 'Alamat',
		started: 'Mulai',

		oproducts: 'Produk Kami',
		remittanceNeeds: 'Untuk kebutuhan remitansi',
		productText1:
			'Melakukan transfer kapan saja, dimana saja dengan biaya ringan. Kami menyediakan layanan transfer kelas dunia, yang mudah, dengan harga terjangkau untuk personal dan bisnis dengan ukuran apapun. ',
		exchangeRate: 'Nilai Tukar',
		amount: 'Jumlah',
		exchange: 'Tukar',
		rate: 'Nilai',
		domesticTransfer: 'Transfer Dalam Negeri',
		sendMoney: 'Kirim uang anda tanpa rasa khawatir',

		productText2: 'Anda dapat melakukan transfer dari Chile, kapan dan dimana saja tanpa perlu khawatir. Proses transaksi akan selesai dalam beberapa menit. ',
		remittance: 'Remitansi antar negara',
		remittanceFP: 'Remittance ke keluarga dan mitra Anda',
		productText3:
			'Anda dapat melakukan transfer untuk teman anda, rekan bisnis anda, kekuarga anda ke luar negeri dengan jaminan keamanan dan kecepatan dengan jumlah penuh. ',
		remittanceAaccounts: 'Remitansi dari Satu menuju Banyak Rekening',
		productText4: 'Pelepasan dana untuk kebutuhan bisnis anda, baik manual dan ter-otimatisasi, baik satu maupun banyak rekening tujuan',

		aboutText1: 'Membangun masa depan teknologi terdepan dalam industri keuangan, untuk memajukan inklusi finansial. ',
		aboutText2:
			'INVERSIONES MAS CREDITO（IMC） adalah layanan jasa remitansi online yang membanty kebutuhan pengiriman uang anda, baik dalam negeri ataupun luar negeri dengan mudah, murah dan cepat. IMC bertujuan memberikan layanan terbaik dan dirancang sesuai dengan kebutuhan pasar di Chile akan layanan pengiriman keuangan, berdasarkan pengalaman dan masukan yang kami dapatkan dari para pelanggan kami. ',
		waw: 'Siapakah Kami? ',
		licensedby: 'Berijin dari',
		License: 'No. 12/42/DASP/10',
		destination: 'Pilih Tujuan',

		transactionEasier: 'Bertransaksi dengan lebih mudah',
		aboutText3: 'Selesaikan transaksi remitansi anda hanya dari ponsel anda tanpa perlu adanya langkah tambahan lainnya. ',
		fasterSafer: 'Lebih Cepat dan Lebih Aman',
		aboutText4: 'Penerima dapat menerima uang dalam beberapa menit dan uang akan dikembalikan jika penerima gagal menerima uang tersebut. ',
		competitivePricing: 'Harga Kompetitif',
		aboutText5: 'Harga lebih rendah, dan biaya pengiriman yang landai. Anda dapat menghemat lebih banyak. ',
		customerService: '7 x 24 Jam Customer Service',
		aboutText6: 'Kami akan melayani anda, kapan dan dimanapun anda berada',
	},
}
