/*
 * @Author: caizhihao
 * @Date: 2023-08-07 18:02:46
 * @LastEditors: caizhihao 177745994@qq.com
 * @LastEditTime: 2023-08-08 11:20:32
 * @FilePath: \PriorityPay-web\src\router\index.js
 * @Description:
 *
 */
import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
	{ path: '/', redirect: '/home' },
	{
		path: '/home',
		name: 'home',
		component: () => import('../views/home/homeIndex.vue'),
	},
	{
		path: '/product',
		name: 'product',
		component: () => import('../views/product/productIndex.vue'),
	},
	{
		path: '/about',
		name: 'about',
		component: () => import('../views/about/aboutIndex.vue'),
	},
]

const router = createRouter({
	history: createWebHashHistory(),
	routes,
})

export default router
